<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Split Hero -->
        <section class="split-hero site-max-width" hero-transition-group>
            <div class="inner split-hero-container">
                <div class="split-hero-content">
                    <h1 class="split-hero-title title" data-inview="fadeInUp" data-delay="100">
                        <span v-html="contentIsLoaded ? content.hero.titre : '&nbsp;'"></span>
                    </h1>
                    <p class="split-hero-text text" data-inview="fadeInUp" data-delay="200">
                        <span v-html="contentIsLoaded ? content.hero.texte : '&nbsp;'"></span>
                    </p>
                </div>
                <div class="split-hero-img" data-inview="fadeInUp" data-delay="400">
                    <img v-if="contentIsLoaded" :src="content.hero.image.url" :alt="content.hero.image.titre" />
                </div>
            </div>
        </section>

        <!-- Grid Thumbs -->
        <section class="grid-thumbs site-max-width" hero-transition-group>
            <div class="inner grid-thumbs-content">
                <h2 class="grid-thumbs-title title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="contentIsLoaded ? content.intro.titre : '&nbsp;'"></span>
                </h2>
                <p class="grid-thumbs-text text" data-inview="fadeInUp" data-delay="200">
                    <span v-html="contentIsLoaded ? content.intro.texte : '&nbsp;'"></span>
                </p>

                <div class="grid-thumbs-items" v-if="pageIsLoaded">
                    <router-link :to="item.page.jsonUrl" class="grid-thumbs-item" data-inview="fadeInUp" :data-delay="(4 + i) + '00'" v-for="(item, i) in episodesEntry" :key="i">
                        <img :src="item.page.imageSimple.url" :alt="item.page.imageSimple.titre" />
                        <div class="content">
                            <h3 class="item-title">{{ item.page.titre }}</h3>
                            <span class="site-btn" :title="$t('Voir plus')">{{ $t('Voir plus') }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>

        <!-- Split Content -->
        <section class="split-content site-max-width" hero-transition-group>
            <div class="inner split-content-container">
                <div class="split-content-content">
                    <h2 class="split-content-title title" data-inview="fadeInUp" data-delay="100">
                        <span v-html="contentIsLoaded ? content.split.titre : '&nbsp;'"></span>
                    </h2>
                    <p class="split-content-text text" data-inview="fadeInUp" data-delay="200">
                        <span v-html="contentIsLoaded ? content.split.texte : '&nbsp;'"></span>
                    </p>
                </div>
                <div class="split-content-imgs" v-if="contentIsLoaded">
                    <a :href="item.lienWeb" target="_blank" rel="noopener noreferrer"
                    v-for="(item, i) in content.split.logos" :key="i"
                        ><img
                            :src="item.url"
                            :alt="item.titre"
                            data-inview="fadeInUp"
                            :data-delay="(4 + i) + '00'"
                    /></a>
                </div>
            </div>
            <div class="inner split-content-container" v-if="contentIsLoaded" >
                <h2 class="split-content-subtitle text" data-inview="fadeInUp" data-delay="100">
                    <span>{{ content.split.sousTitre }}</span>
                </h2>
                <div class="logos-grid" data-inview="fadeInUp" data-delay="300">
                    <a :href="item.lienWeb" target="_blank" rel="noopener noreferrer"
                    v-for="(item, i) in content.split.grilleDeLogos" :key="i"
                        ><img
                            :src="item.url"
                            :alt="item.titre"
                    /></a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Home',

    components: {
        // HelloWorld,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        episodesEntry() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.episodesEntry.data.slice(0, 6)
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
